<template>
  <div style="background-color: #F5F5F5;padding: 30px">
    <safe-area>
      <div class="index">
        <div class="title">
          <div class="redPoint"></div>
          <div style="font-size: 12px;margin-left: 6px">{{ lang === 'en' ? 'Lens calculation' : '透镜计算' }}</div>
        </div>
        <div class="choose">
          <el-radio-group v-model="chooseIndex" @change="change">
            <el-radio :label="1">{{ lang === 'en' ? 'Focal length calculation' : '焦距计算' }}</el-radio>
            <el-radio :label="2">{{ lang === 'en' ? 'Curvature calculation' : '曲率计算' }}</el-radio>
          </el-radio-group>
        </div>
        <div v-if="chooseIndex === 1" class="computed">
          <div class="contain">
            <div style="margin:0 15px 0 0">{{ lang === 'en' ? 'Material:' : '材料:' }}</div>

            <el-select v-model="material" :placeholder="lang === 'en' ? 'Please select material' : '请选择材料'"
                       style="width: 150px">
              <el-option
                  v-for="item in materials"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <div v-if="material !== ''" style="margin:0 15px">{{ lang === 'en' ? 'Design wavelength:' : '设计波长:' }}</div>
            <el-select v-if="material !== ''" v-model="blong"
                       :placeholder="lang === 'en' ? 'Please select the design wavelength' : '请选择设计波长'"
                       style="width: 120px">
              <el-option
                  v-for="item in blongs"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <div style="margin-left: 15px;">{{ lang === 'en' ? 'Curvity:' : '曲率:' }}</div>
            <el-input v-model="curvature" class="inputs" style="width: 160px;"
                      :placeholder="lang === 'en' ? 'Please input curvity' : '请输入曲率:'"/>
            <div style="margin:0 15px">{{ lang === 'en' ? 'Refractive index of material:' : '材料折射率:' }}</div>
            <el-input v-model="refractive" class="inputs" disabled style="width: 150px;margin-right: 15px"
                      :placeholder="lang === 'en' ? 'Refractive index of material' : '材料折射率'"/>
            <el-button type="danger" @click="computed(1)" style="margin: 10px 5px">
              {{ lang === 'en' ? 'Calculated focal length' : '计算焦距' }}
            </el-button>
            <span v-if="show" style="font-size: 17px">{{
                lang === 'en' ? 'Result：' + focalRe : '结果为：' + focalRe
              }}</span>

          </div>
        </div>
        <div v-if="chooseIndex === 2" class="computed">
          <div class="contain">
            <div style="margin:0 15px 0 0">{{ lang === 'en' ? 'Material:' : '材料:' }}</div>
            <el-select v-model="material" :placeholder="lang === 'en' ? 'Please select material' : '请选择材料'"
                       style="width: 150px">
              <el-option
                  v-for="item in materials"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <div v-if="material !== ''" style="margin:0 15px">{{ lang === 'en' ? 'Design wavelength:' : '设计波长:' }}</div>
            <el-select v-if="material !== ''" v-model="blong"
                       :placeholder="lang === 'en' ? 'Please select the design wavelength' : '请选择设计波长'"
                       style="width: 120px">
              <el-option
                  v-for="item in blongs"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <div style="margin-left: 15px">{{ lang === 'en' ? 'Focal length:' : '焦距:' }}</div>
            <el-input v-model="focal" class="inputs"
                      :placeholder="lang === 'en' ? 'Please input focal length:' : '请输入焦距'"/>
            <div style="margin:0 15px">{{ lang === 'en' ? 'Refractive index of material:' : '材料折射率:' }}</div>
            <el-input v-model="refractive" class="inputs" disabled
                      :placeholder="lang === 'en' ? 'Refractive index of material' : '材料折射率'"/>
            <el-button style="margin-left: 15px" type="danger" @click="computed(2)">
              {{ lang === 'en' ? 'Calculate curvature' : '计算曲率' }}
            </el-button>
          </div>
          <span v-if="show" style="font-size: 17px">{{
              lang === 'en' ? 'Result：' + curvatureRe : '结果为：' + curvatureRe
            }}</span>
        </div>
        <div v-show="false"> {{ copRefractive() }}
        </div>
      </div>
      <div class="index">
        <div class="title">
          <div class="redPoint"></div>
          <div style="font-size: 12px;margin-left: 6px">{{ lang === 'en' ? 'Wave plate calculation' : '波片计算' }}</div>
        </div>
        <div class="choose">
          <el-radio-group v-model="chooseIndexs" @change="changes">

            <el-radio :label="1">{{ lang === 'en' ? 'Thickness calculation' : '厚度计算' }}</el-radio>

          </el-radio-group>
        </div>

        <div v-if="chooseIndexs === 1" class="computed">
          <div class="contain">
            <div style="display: flex;align-items: center;margin-right:15px">
              <div>{{ lang === 'en' ? 'Material:' : '材料:' }}</div>
              <el-input class="inputs" disabled placeholder="材料折射率" value="Quartz"/>
            </div>
            <div style="display: flex;align-items: center;margin-right:15px">
              <div style="margin-right:15px">{{ lang === 'en' ? 'Use wavelength:' : '使用波长:' }}</div>
              <el-select v-model="long" :placeholder="lang === 'en' ? 'Please select the wavelength to use' : '请选择使用波长'"
                         style="width: 160px">
                <el-option
                    v-for="item in longs"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div v-if="long !==''" style="display: flex;align-items: center;margin-right:15px">
              <div style="margin-right:15px">{{ lang === 'en' ? 'Wave plate phase delay:' : '波片相位延迟:' }}</div>
              <el-select v-model="delay" :placeholder="lang === 'en' ? 'Please select phase delay' : '请选择相位延迟'"
                         style="width: 160px">
                <el-option
                    v-for="item in delays"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div style="display: flex;align-items: center;margin-right:15px">
              <div style="margin-right:15px">{{ lang === 'en' ? 'Refractive index' : '折射率' }}(No-Ne):</div>
              <div v-show="false"> {{ copRefractives() }}</div>
              <el-input v-model="Orefraction" class="inputs" disabled placeholder="材料折射率"/>
            </div>
            <div style="display: flex;align-items: center;margin-right:15px">
              <div>{{ lang === 'en' ? 'Wave plate grade:' : '波片等级:' }}</div>
              <el-input v-model="level" class="inputs"
                        :placeholder="lang === 'en' ? 'Please enter the wave plate level' : '请输入波片等级'"
                        @keydown.native="channelInputLimit"
              />
            </div>


            <el-button style="margin:15px 15px 15px 0" type="danger" @click="computeds(1)">
              {{ lang === 'en' ? 'Calculated thickness' : '计算厚度' }}
            </el-button>
            <span v-if="shows" style="font-size: 17px">{{
                lang === 'en' ? 'Result：' + thicknessRe + 'mm' : '结果为：' + thicknessRe + 'mm'
              }}</span>

          </div>
        </div>
      </div>
    </safe-area>

  </div>
</template>

<script>
import SafeArea from "@/layout/safe-area";
import {getLang} from "@/utils/lang";

export default {
  name: "tool",
  components: {SafeArea},
  data() {
    return {
      lang: getLang(),
      chooseIndex: 1,
      chooseIndexs: 1,
      curvature: '',
      material: '',
      materials: [{
        value: 'N-BK7',
        label: 'N-BK7'
      }, {
        value: 'UVFS',
        label: 'UVFS'
      }],
      blong: '',
      blongs: [{
        value: '532nm',
        label: '532nm'
      }, {
        value: '632.8nm',
        label: '632.8nm'
      }, {
        value: '1064nm',
        label: '1064nm'
      }],
      refractive: '',
      focal: '',
      curvatureRe: '',
      refractiveRe: '',
      focalRe: '',
      show: false,
      shows: false,
      btype: 'danger',
      Orefraction: '',
      Orefractions: [{
        value: '0.00874',
        label: '0.00874'
      }, {
        value: '0.0092',
        label: '0.0092'
      }],
      thickness: '',
      level: '',
      delay: '',
      delays: [{
        value: '4',
        label: 'λ/4'
      }, {
        value: '2',
        label: 'λ/2'
      }],
      OrefractionRe: '',
      thicknessRe: '',
      levelRe: '',
      delayRe: '',
      long: '',
      longs: [{
        value: '1064',
        label: '1064nm'
      }, {
        value: '532',
        label: '532nm'
      }],
      longRe: ''
    }
  },
  methods: {
    channelInputLimit(e) {
      let key = e.key
      // 不允许输入'e'和'.'
      if (key === '-' || key === '.') {
        e.returnValue = false
        return false
      }
      return true
    },
    copRefractive() {
      if (this.material === 'N-BK7') {
        if (this.blong === '632.8nm') {
          this.refractive = 1.515089
          return 1.515089
        }
        if (this.blong === '532nm') {
          this.refractive = 1.519473
          return 1.519473
        }
        if (this.blong === '1064nm') {
          this.refractive = 1.506635
          return 1.506635
        }
      }
      if (this.material === 'UVFS') {
        if (this.blong === '632.8nm') {
          this.refractive = 1.457018
          return 1.457018
        }
        if (this.blong === '532nm') {
          this.refractive = 1.460706
          return 1.460706
        }
        if (this.blong === '1064nm') {
          this.refractive = 1.449631
          return 1.449631
        }
      }
    },
    copRefractives() {
      if (this.long === '1064') {
        this.Orefraction = 0.00874
        return 0.00874
      }
      if (this.long === '532') {
        this.Orefraction = 0.0092
        return 0.0092
      }
    },
    change() {
      this.curvature = '',
          this.material = '',
          this.blong = '',
          this.refractive = '',
          this.focal = '',
          this.curvatureRe = '',
          this.refractiveRe = '',
          this.focalRe = '',
          this.show = false
    },
    changes() {
      this.Orefraction = '',
          this.thickness = '',
          this.level = '',
          this.delay = '',
          this.OrefractionRe = '',
          this.thicknessRe = '',
          this.levelRe = '',
          this.delayRe = '',
          this.long = '',
          this.shows = false
    },
    computed(i) {
      if (i === 1) {
        this.focalRe = (Number(this.curvature) / (Number(this.refractive) - 1)).toFixed(3)
        this.show = true
      }
      if (i === 2) {
        this.curvatureRe = (Number(this.focal) * (Number(this.refractive) - 1)).toFixed(3)
        this.show = true
      }

    },
    computeds(i) {
      if (i === 1) {
        this.thicknessRe = (((2 * Number(this.level) + 1) * Number(this.long / 1000) / Number(this.delay) / Number(this.Orefraction)) / 1000).toFixed(4)
        console.log((2 * 0 + 1) * 1.064 / 2 / 0.00874)
        this.shows = true
      }
    }
  }
}
</script>

<style scoped>
.index {
  width: 100%;
  height: 200px;
  background-color: #fff;
  padding: 20px 20px 0 20px;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.redPoint {
  width: 6px;
  height: 16px;
  background-color: red;
}

.choose {
  margin-bottom: 15px;
}

.computed {
  font-size: 14px;
}

.contain {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  flex-wrap: wrap;
  padding: 10px 0;
}

.inputs {
  width: 140px;
  margin-left: 10px;
}
</style>
