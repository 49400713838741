<template>
  <div>
    <post-list :type="3"></post-list>
  </div>
</template>

<script>
import PostList from "@/components/post-list/post-list";
// todo 技术文章样式优化
export default {
  name: "post",
  components: {PostList}
}
</script>

<style scoped>

</style>
